<template>
  <div class="personnal-infos-dialog rounded-lg pa-4">
    <div class="pa-4 rounded-lg white">
      <j-stepper-labels v-if="![0,1,5].includes(step)" class="mx-auto wi-75 wi-sm-100 mb-0 mb-md-4" @change="step = $event" v-bind="{active: step, steps: steps}"></j-stepper-labels>
      <v-carousel touchless height="auto" v-model="step" :show-arrows="false" :hide-delimiters="true">
        <v-carousel-item content-class="d-flex flex-column text-grey align-center justify-center">
          <img class="wi-15 wi-sm-50" src="/images/3d/contract.png" alt="Contrat"/>
          <h4 class="mt-5 mb-4 text-center">Nous avons mis à jour nos conditions d'utilisation.</h4>
          <p class="text-center mb-2">Merci de les accepter afin de pouvoir continuer à utiliser <span class="font-weight-bold primary--text">MyJobAcademy</span>.</p>
          <j-user-form @focus="errorsPresents = false" @validate="validate" :usage="['optinMJA']" :submitWord="'Valider'" :button="{color: 'red-gradient', size: 'large'}"></j-user-form>
          <v-expand-transition>
            <v-alert v-if='errorsPresents' class="mt-5 mb-0 red lighten-2 white_ text-center">{{errorMessage}}</v-alert>
          </v-expand-transition>
        </v-carousel-item>
        <v-carousel-item content-class="d-flex flex-column text-grey align-center justify-center">
          <div class="wi-50 wi-sm-80 mb-8 rounded-lg video-container">
            <iframe class="vimeo-iframe" src="https://player.vimeo.com/video/873353989?title=0&byline=0&portrait=0" frameborder="0" allowfullscreen></iframe>
          </div>
          <h4 class="text-center font-weight-bold mb-4">Nous avons besoin d'en savoir plus sur vous !</h4>
          <p class="text-center mb-8">Merci de répondre au formulaire suivant afin de pouvoir personnaliser au mieux votre expérience <span class="primary--text font-weight-bold">MyJobAcademy</span>.<br>Nous vous conseillons de le remplir sérieusement. Bien sûr, vous pourrez par la suite mettre à jour ces informations.<br><span class="text-caption">(Cela ne prendra pas plus de 5 minutes)</span></p>
          <v-btn @click="navigate(1)" class="j-btn white_ primary mb-2">commencer</v-btn>
        </v-carousel-item>
        <v-carousel-item content-class="d-flex flex-column text-grey">
          <v-expansion-panels class="mt-4" flat>
            <v-expansion-panel v-for="(exp, i) in inf.experiences" :key="i">
              <v-expansion-panel-header>
                <div class="d-flex flex-column flex-md-row text-center align-center">
                  <div class="index-badge secondary white_"><p>{{i+1}}</p></div> 
                  <p class="mb-0 font-weight-bold text-grey">{{exp.company ? exp.company : 'Expérience'}} :</p>
                  <v-chip class="mt-2 mt-md-0 ml-0 ml-md-2 white_" :color="validatorInfos('experiences', i).validate ? 'green':'red'" outlined small>{{validatorInfos('experiences', i).fields}}/3</v-chip>
                  <v-btn v-if="inf.experiences.length > 1" @click="remove('experiences', i)" class="mt-2 mt-md-0 ml-0 ml-md-2 text-alert" small icon><v-icon>mdi-delete</v-icon></v-btn>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div class="d-flex flex-column flex-md-row mt-4 mb-8">
                  <v-text-field class="wi-50 wi-sm-100 pr-0 pr-md-4" v-model="inf.experiences[i].title" label="Intitulé du poste *" placeholder="Ex: Chef de projet, Vendeur, ..." persistent-placeholder outlined clearable hide-details></v-text-field>
                  <v-text-field class="wi-50 wi-sm-100 mt-8 mt-md-0 pl-0 pl-md-4" v-model="inf.experiences[i].company" label="Entreprise *" placeholder="Ex: Orange, Hermès, ..." persistent-placeholder outlined clearable hide-details></v-text-field>
                </div>
                <quill-editor :value="inf.experiences[i].tasks" @change="inf.experiences[i].tasks = $event.html" :options="{modules, placeholder: 'Rédigez ici vos missions *'}"></quill-editor>
                <div class="d-flex mt-8">
                  <v-select class="wi-50 pr-4" placeholder="Mois" label="Date de début du contrat (optionnel)" :items="months" item-text="txt" item-value="id" v-model="inf.experiences[i].begin.month" persistent-placeholder outlined clearable dense hide-details>
                    <template v-slot:selection="{ item }">
                        {{item.txt[0].toUpperCase() + item.txt.substring(1)}}
                      </template>
                      <template v-slot:item="{ item }">
                        {{item.txt[0].toUpperCase() + item.txt.substring(1)}}
                      </template>
                  </v-select>
                  <v-select class="wi-50 pl-4" placeholder="Année" :items="years" v-model="inf.experiences[i].begin.year" outlined clearable dense hide-details></v-select>
                </div>
                <div :class="[{'grey--text current-job':inf.experiences[i].current},'end-selects d-flex mt-8']">
                  <v-select :disabled="inf.experiences[i].current" class="wi-50 pr-4" placeholder="Mois" label="Date de fin (optionnel)" :items="months" item-text="txt" item-value="id" v-model="inf.experiences[i].end.month" persistent-placeholder outlined clearable dense hide-details>
                    <template v-slot:selection="{ item }">
                      {{item.txt[0].toUpperCase() + item.txt.substring(1)}}
                    </template>
                    <template v-slot:item="{ item }">
                      {{item.txt[0].toUpperCase() + item.txt.substring(1)}}
                    </template>
                  </v-select>
                  <v-select :disabled="inf.experiences[i].current" class="wi-50 pl-4" placeholder="Année" :items="years" v-model="inf.experiences[i].end.year" outlined clearable dense hide-details></v-select>
                </div>
                <v-checkbox v-model="inf.experiences[i].current" @change="setCurrent($event, 'experiences', i)" class="my-4 px-2 checkbox-label-ma" label="J'occupe actuellement ce poste" dense hide-details></v-checkbox>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-btn @click="add('experiences')" class="secondary--text mx-auto my-4" outlined icon><v-icon>mdi-plus</v-icon></v-btn>
        </v-carousel-item>
        <v-carousel-item content-class="d-flex flex-column text-grey">
          <v-expansion-panels class="mt-4" flat>
            <v-expansion-panel v-for="(edu, i) in inf.educations" :key="i">
              <v-expansion-panel-header>
                <div class="d-flex flex-column flex-md-row text-center align-center">
                  <div class="index-badge secondary white_"><p>{{i+1}}</p></div> 
                  <p class="mb-0 font-weight-bold text-grey" v-once>{{edu.degree ? edu.degree : 'Formation'}} :</p>
                  <v-chip class="mt-2 mt-md-0 ml-0 ml-md-2 white_" :color="validatorInfos('educations', i).validate ? 'green':'red'" outlined small>{{validatorInfos('educations', i).fields}}/3</v-chip>
                  <v-btn v-if="inf.educations.length > 1" @click="remove('educations', i)" class="mt-2 mt-md-0 ml-0 ml-md-2 text-alert" small icon><v-icon>mdi-delete</v-icon></v-btn>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div class="d-flex flex-column flex-md-row mt-4 mb-8">
                  <v-text-field class="wi-50 wi-sm-100 pr-0 pr-md-4" v-model="inf.educations[i].degree" label="Diplôme de la formation *" placeholder="Ex: Licence en droit, ..." persistent-placeholder outlined clearable hide-details></v-text-field>
                  <v-text-field class="wi-50 wi-sm-100 mt-8 mt-md-0 pl-0 pl-md-4" v-model="inf.educations[i].school" label="École *" placeholder="Ex: École, Lycée, ..." persistent-placeholder outlined clearable hide-details></v-text-field>
                </div>
                <quill-editor :value="inf.educations[i].description" @change="inf.educations[i].description = $event.html" :options="{modules, placeholder: 'Rédigez ici vos matières clés et vos projets étudiants *'}"></quill-editor>
                <div class="d-flex mt-8">
                  <v-select class="wi-50 pr-4" placeholder="Mois" label="Date de début (optionnel)" :items="months" item-text="txt" item-value="id" v-model="inf.educations[i].begin.month" persistent-placeholder outlined clearable dense hide-details>
                    <template v-slot:selection="{ item }">
                        {{item.txt[0].toUpperCase() + item.txt.substring(1)}}
                      </template>
                      <template v-slot:item="{ item }">
                        {{item.txt[0].toUpperCase() + item.txt.substring(1)}}
                      </template>
                  </v-select>
                  <v-select class="wi-50 pl-4" placeholder="Année" :items="years" v-model="inf.educations[i].begin.year" outlined clearable dense hide-details></v-select>
                </div>
                <div class="d-flex mt-8">
                  <v-select class="wi-50 pr-4" placeholder="Mois" label="Date de fin, ou prévue (optionnel)" :items="months" item-text="txt" item-value="id" v-model="inf.educations[i].end.month" persistent-placeholder outlined clearable dense hide-details>
                    <template v-slot:selection="{ item }">
                      {{item.txt[0].toUpperCase() + item.txt.substring(1)}}
                    </template>
                    <template v-slot:item="{ item }">
                      {{item.txt[0].toUpperCase() + item.txt.substring(1)}}
                    </template>
                  </v-select>
                  <v-select class="wi-50 pl-4" placeholder="Année" :items="years" v-model="inf.educations[i].end.year" outlined clearable dense hide-details></v-select>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-btn @click="add('educations')" class="secondary--text mx-auto my-4" outlined icon><v-icon>mdi-plus</v-icon></v-btn>
        </v-carousel-item>
        <v-carousel-item content-class="d-flex flex-column">
          <v-divider class="mt-8"></v-divider>
          <div class="d-flex flex-column flex-md-row text-center align-center mb-4">
            <div class="index-badge secondary white_"><v-icon color="white">mdi-chevron-right</v-icon></div> 
            <p class="mb-0 font-weight-bold text-grey">Votre objectif professionnel et vous :</p>
            <v-chip class="mt-2 mt-md-0 ml-0 ml-md-2 white_" :color="!!inf.goal && !!inf.sector ? 'green':'red'" outlined small>{{[inf.goal, inf.sector].filter(Boolean).length}}/2</v-chip>
          </div>
          <div class="d-flex flex-column flex-md-row mt-4 mt-md-0 mb-8">
            <v-text-field class="wi-50 wi-sm-100 pr-0 pr-md-4" v-model="inf.goal" label="À quel poste aspirez-vous ? *" placeholder="Ex: Directeur des ventes, ..." persistent-placeholder outlined clearable hide-details></v-text-field>
            <v-text-field class="wi-50 wi-sm-100 mt-8 mt-md-0 pl-0 pl-md-4" v-model="inf.sector" label="Quel est votre secteur d'activité ? *" placeholder="Ex: Ressources humaines, Ventes, ..." persistent-placeholder outlined clearable hide-details></v-text-field>
          </div>
          <v-textarea class="wi-100 mb-8" v-model="inf.reasons" label="Pour quelles raisons ? (optionnel)" placeholder="Ex: Ressources humaines, Ventes, ..." persistent-placeholder outlined clearable hide-details></v-textarea>
        </v-carousel-item>
        <v-carousel-item content-class="d-flex flex-column align-center justify-center">
          <img class="wi-30 wi-sm-50 mb-8" src="/images/3d/interview-bg.png" />
          <h4 class="text-center font-weight-bold mb-4">Merci pour vos réponses !</h4>
          <p class="text-center mb-8">Vous allez pouvoir profiter pleinement de tous les outils de <span class="primary--text font-weight-bold">MyJobAcademy</span>.<br>Rendez-vous sur la page d'accueil pour savoir par où commencer.</p>
          <v-btn @click="$router.push('/')" class="j-btn red-gradient white_ mb-2">se rendre sur la<br v-if="$vuetify.breakpoint.smAndDown"> page d'accueil</v-btn>
        </v-carousel-item>
      </v-carousel>
      <div v-if="![0,1,5].includes(step)" class="personnal-infos-actions d-flex align-center justify-space-between my-2">
        <v-btn v-if="$vuetify.breakpoint.smAndDown" :disabled="[0,1,2].includes(step)" outlined class="j-btn ma-0 grey--text" @click="navigate(-1)" icon><v-icon>mdi-chevron-left</v-icon></v-btn>
        <v-btn v-else :disabled="[0,1,2].includes(step)" outlined class="j-btn ma-0 grey--text" @click="navigate(-1)"><v-icon left>mdi-chevron-left</v-icon> précédent</v-btn>
        <div class="actions-center d-flex flex-column align-center justify-center">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <span v-on="!globalValidator && on">
                <v-btn :disabled="!globalValidator" @click="send()" class="form-btn j-btn red-gradient white_ mb-1">enregistrer</v-btn>
              </span>
            </template>
            <span>Veuillez remplir tous les champs de chaque étape.</span>
          </v-tooltip>
          <v-btn @click="$emit('close')" class="j-btn grey--text mt-1" text>annuler</v-btn>
        </div>
        <v-btn v-if="$vuetify.breakpoint.smAndDown" :disabled="[4,5].includes(step)" outlined class="j-btn ma-0 grey--text" @click="navigate(1)" icon><v-icon>mdi-chevron-right</v-icon></v-btn>
        <v-btn v-else :disabled="[4,5].includes(step)" outlined class="j-btn ma-0 grey--text" @click="navigate(1)">suivant <v-icon right>mdi-chevron-right</v-icon></v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { apiURL } from '@/../config'
import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'

export default {
  name: 'infos-dialog',
  components: {
    quillEditor
  },
  data() {
    return {
      step: 0,
      months: [{ id: 0, txt: 'janvier' },{ id: 1, txt: 'février' },{ id: 2, txt: 'mars' },{ id: 3, txt: 'avril' },{ id: 4, txt: 'mai' },{ id: 5, txt: 'juin' },{ id: 6, txt: 'juillet' },{ id: 7, txt: 'août' },{ id: 8, txt: 'septembre' },{ id: 9, txt: 'octobre' },{ id: 10, txt: 'novembre' },{ id: 11, txt: 'décembre' }],
      modules: { toolbar: { container: [['bold', 'italic', 'underline', 'strike'],[{ 'list': 'bullet' }],['clean']] } },
      inf: {
        experiences: [{title: '', company: '', current: false, begin: {month: '', year: ''}, end: {month: '', year: ''}, tasks: ''}],
        educations: [{degree: '', school: '', begin: {month: '', year: ''}, end: {month: '', year: ''}, description: ''}],
        goal: '',
        sector: '',
        reasons: ''
      },
      errorsPresents: false,
      errorMessage: "Une erreur est survenue, veuillez réessayer."
    }
  },
  mounted() {
    if(!this.needOptin) {
      if(this.updating) this.navigate(2)
      else this.navigate(1)
    }
    if(this.updating) this.inf = JSON.parse(JSON.stringify(this.$store.getters.getProfile.register_infos))
  },
  methods: {
    async validate({user}) {
      try {
        this.$store.dispatch('loading')
        const recaptcha = await this.$recaptcha('picture_upload')
        await axios.post(`${apiURL}auth/me/optin`, {optin: user.optin, recaptcha})
        await this.$store.dispatch('optin_accepted')
        if(this.updating) this.$store.dispatch('close_optin')
        else this.step++
      } catch(e){
        this.errorsPresents = true
        this.errorMessage = "Une erreur est survenue, veuillez réessayer."
      } finally {
        this.$store.dispatch('stop_loading')
      }
    },
    validatorInfos(slug, index) {
      const obj = this.inf[slug][index]
      let validate = false
      let fields = 0
      if(slug === 'experiences') {
        validate = obj.company && obj.title && obj.tasks
        fields = [obj.company, obj.title, obj.tasks].filter(Boolean).length
      }
      else if(slug === 'educations') {
        validate = obj.degree && obj.school && obj.description
        fields = [obj.degree, obj.school, obj.description].filter(Boolean).length
      }
      return {validate, fields}
    },
    navigate(val) {
      this.step = this.step + val
      document.querySelector('.v-dialog').scroll({top:0,behavior:'smooth'})
    },
    add(field) {
      if(field === 'experiences') this.inf.experiences.push({title: '', company: '', current: false, begin: {month: '', year: ''}, end: {month: '', year: ''}, tasks: ''})
      if(field === 'educations') this.inf.educations.push({degree: '', school: '', begin: {month: '', year: ''}, end: {month: '', year: ''}, description: ''})
    },
    remove(field, index) {
      this.inf[field].splice(index, 1)
    },
    setCurrent(value, field, i) {
      if(value) this.inf[field][i].end = {month: '', year: ''}
    },
    async send() {
      try {
        this.$store.dispatch('loading')
        await this.$recaptchaLoaded()
        const recaptcha = await this.$recaptcha('registerInfos')
        this.$store.dispatch('post_register_infos', {register_infos: this.inf, recaptcha })
        this.$store.dispatch('stop_loading')
        if(this.updating) this.$emit('close')
        else this.step = 4
      } catch(e) {
        this.$store.dispatch('stop_loading')
        this.$store.dispatch('open_dialog',{message:"Une erreur est survenue, veuillez réessayer"})
      }
    }
  },
  computed: {
    steps() {
      return [{value: '💼', text: 'Vos expériences', active: 2, validator: this.validator.experiences},{value: '🎓', text: 'Vos études', active: 3, validator: this.validator.educations},{value: '🎯', text: 'Votre objectif', active: 4, validator: this.validator.goal}]
    },
    validator() {
      return {
        experiences: this.inf.experiences.length === this.inf.experiences.filter(exp => { return !!exp.company && !!exp.title && !!exp.tasks }).length,
        educations: this.inf.educations.length === this.inf.educations.filter(edu => { return !!edu.degree && !!edu.school && !!edu.description }).length,
        goal: !!this.inf.goal && !!this.inf.sector
      }
    },
    globalValidator() {
      return this.validator.experiences && this.validator.educations && this.validator.goal
    },
    needOptin() {
      return !this.$store.getters.getProfile.optin
    },
    updating() {
      return this.$store.getters.getProfile.infos_replied
    },
    years() {
      const years = []
      for (let index = parseInt(new Date().getFullYear()+1); index >= 1950; index--) { years.push(index.toString()) }
      return years
    },
    yearsNext() {
      const years = []
      for (let index = parseInt(new Date().getFullYear()+10); index >= 1950; index--) { years.push(index.toString()) }
      return years
    }
  }
}
</script>

<style lang="scss">
.personnal-infos-dialog {
  background: $mainGradientPastel;
  .video-container {
    position: relative;
    overflow: hidden;
    aspect-ratio: 16/9;
    .vimeo-iframe {
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }
  .v-responsive__content {
    min-height: 60vh;
  }
  .ql-container.ql-snow {
    height: 30vh;
  }
  .end-selects {
    transition: all ease 0.25s;
    &.current-job {
      opacity: 0.5;
    }
  }
  .v-expansion-panels .v-expansion-panel {
    border-radius: 0px!important;
    border-top: #e9e9e9 1px solid;
    border-bottom: #e9e9e9 1px solid;
    .v-expansion-panel-header {
      padding: 16px 0;
    }
    .v-expansion-panel-content__wrap {
      padding: 0 0 16px 0;
    }
  }
  .index-badge {
    height: 32px;
    width: 32px;
    padding: 4px;
    margin: 8px;
    border-radius: 9999px;
    text-align: center;
    display: flex;
    background-color: $secondary;
    p {
      margin: auto;
    }
  }
  .personnal-infos-actions {
    position: relative;
    padding: 20px 0px;
    .actions-center {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .v-btn.v-btn--disabled:not(.form-btn) {
      visibility: hidden;
    }
  } 
}
</style>
