<template>
  <div class="wju-container text-grey d-flex flex-column align-center justify-center">
    <h2 class="font-weight-bold mb-2">Pourquoi rejoindre notre entreprise ?</h2>
    <p class="text-center mb-6">Aidez vous de l’intelligence artificielle pour répondre à cette question.<br>Renseignez le nom de l’entreprise pour votre prochain entretien.</p>
    <v-form @submit.prevent="send()" class="search-bar wi-75 wi-sm-100 d-flex mb-10">
      <v-text-field v-model="search" :disabled="pending" @focus="searchFocus = true" @blur="searchFocus = false" :class="[{radiation:!searchFocus&&!valideSearch},'flex-grow-1']" type="text" solo background-color="white" placeholder="Le nom de l'entreprise" hide-details clearable>
        <v-icon slot="prepend-inner" color="grey">mdi-magnify</v-icon>
      </v-text-field>
      <v-btn type="submit" :disabled="!valideSearch || pending" class="white_ search-button red-gradient">Rechercher</v-btn>
    </v-form>
    <div class="wju-content wi-100 d-flex flex-column py-4 pr-2">
      <div v-if="asked" class="mr-auto">
        <div class="d-flex message-animation wi-80 wi-sm-100">
          <profile-bubble :image="`/images/3d/coach_${coachIndex}.png`" class="BGLight-primary mr-2 mr-md-4"></profile-bubble>
          <div v-if="response.length" class="message-content message-animation text-grey elevation-1">
            <p class="ma-0 text-pre-wrap">{{response}}</p>
          </div>
          <typing-bubble v-else class="message-animation"></typing-bubble>
        </div>
      </div>
      <div v-else class="ma-auto wi-60 wi-sm-100 d-flex flex-column flex-md-row align-center">
        <img class="icon-img-10 mr-0 mr-md-4" src="/images/3d/interview-questions.png"/>
        <p class="mb-0 ml-0 ml-md-2 text-center text-md-left">Commencez à chercher le nom d'une entreprise pour savoir comment répondre à la question : "Pourquoi souhaitez-vous nous rejoindre ?".</p>
      </div>
    </div>
  </div>
</template>

<script>
import ProfileBubble from '@/assets/jobtimise-common/components/chat/ProfileBubble'
import TypingBubble from '@/assets/jobtimise-common/components/chat/TypingBubble'
import axios from 'axios'
import { apiURL } from '@/../config'
import { io } from "socket.io-client"
const socket = io(apiURL)

export default {
  name: 'why-join-us',
  components: {ProfileBubble,TypingBubble},
  data() {
    return {
      searchFocus: false,
      asked: false,
      pending: false,
      search: '',
      response: '',
      coachIndex: 1
    }
  },
  mounted() {
    socket.on('wjuResChunk', (data) => {
      this.response = data.content
    })
    socket.on('wjuResEnd', () => {
      this.pending = false
    })
    socket.on('resError', () => {
      this.send()
    })
  },
  created() {
    this.coachIndex = Math.floor((Math.random()*4)+1)
  },
  computed: {
    valideSearch() {
      return this.search.length > 2
    }
  },
  methods: {
    async send() {
      try {
        this.asked = true
        this.pending = true
        this.response = ''
        await axios.get(`${apiURL}auth/why-join-us/${socket.id}?search=${this.search}`)
      } catch(e) {
        this.$store.dispatch('open_dialog',{message:'Erreur lors de la requête. Veuillez réessayer.'})
      }
    }
  }
}
</script>

<style lang="scss">
.wju-container {
  .search-bar {
    .v-text-field {
      position: relative;
      border-top-left-radius: 50rem;
      border-bottom-left-radius: 50rem;
      .v-input__control {
        z-index: 2;
      }
      &:after, &:before{
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        width: calc(100%);
        height: 100%;
        background: white;
        border-top-left-radius: 50rem;
        border-bottom-left-radius: 50rem;
        animation : input-radiation 1.8s ease-in-out infinite; 
        z-index: 1;
        display: none;
      }
      &::before{
        animation-delay: 0.4s;
      }
      &.radiation{
        &::after,&::before{
          display: block;
          @include phone {
            display: none;
          }
        }
      }
    }
    .search-button {
      border-top-right-radius: 50rem;
      border-bottom-right-radius: 50rem;
      height: auto!important;
      z-index: 0;
      span {
        font-weight: 700;
      }
    }
  }
  .wju-content {
    overflow-y: scroll;
    height: 50vh;
    @include phone {
      height: 90vh;
    }
  }
  .message-content {
    height: fit-content;
    padding: 1rem 1.5rem;
    border-radius: 2.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    @include phone {
      max-width: calc(100% - 4rem);
      border-radius: 2rem;
      padding: 0.5rem 1.25rem;
    }
  }
  .message-animation {
    animation: inBottom 0.5s;
    animation-fill-mode: forwards;
    opacity: 0;
  }
  .typing-bubble {
    padding: 16px;
    >div {
      background: white;
    }
    span {
      background: grey;
    }
  }
}
</style>
