module.exports = {
  apiURL: process.env.VUE_APP_API_URL,
  scrapingURL: process.env.VUE_APP_API_SCRAPING,
  env: process.env.VUE_APP_ENV,
  stripePublic: process.env.VUE_APP_STRIPEPUBLIC,
  jobEden: process.env.VUE_APP_MODS.includes('jobeden'),
  myjobacademy: process.env.VUE_APP_MODS.includes('myjobacademy'),
  registerApp: process.env.VUE_APP_MODS.includes('registerapp'),
  partnerApp: process.env.VUE_APP_MODS.includes('partnerapp'),
  partner: process.env.VUE_APP_PARTNER,
  partnerLogo: process.env.VUE_APP_LOGO,
  partnerLogoMobile: process.env.VUE_APP_LOGO_MOBILE,
  schoolApp: process.env.VUE_APP_MODS.includes('schoolapp'),
  quizCatchup: process.env.VUE_APP_MODS.includes('quizcatchup'),
  demo: process.env.VUE_APP_MODS.includes('demo'),
  coach: process.env.VUE_APP_MODS.includes('coach'),
  hiddenOffer: process.env.VUE_APP_MODS.includes('hiddenoffer'),
  course: process.env.VUE_APP_MODS.includes('course'),
  collective: process.env.VUE_APP_MODS.includes('collective'),
  logbookAccess: process.env.VUE_APP_MODS.includes('logbookaccess'),
  moderateQuestions: process.env.VUE_APP_MODS.includes('moderatequestions'),
  commonQuestions: process.env.VUE_APP_MODS.includes('commonquestions'),
  maxChats: process.env.VUE_APP_MAXCHATS,
  collectiveInfos: process.env.VUE_APP_MODS.includes('collectiveinfos')
}
